import {HTTP} from '../utils/axios'

class UsersModel extends HTTP {
  /*首页渲染*/
  index(key = '',page = 1) {
      return this.request({
          url: 'board/admin_manages',
          method: "GET",
          data: {
            key: key,
            page: page
          }
      })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: 'board/admin_manages',
        method: "POST",
        data: value,
    })
  }

   /*修改*/
   update(value, url) {
    return this.request({
        url: url,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy( id) {
    return this.request({
        url: 'board/admin_manages/' + id,
        method: "DELETE",
    })
  }

  /*批量删除*/
  destroyMultiple( value) {
    return this.request({
        url: 'board/admin_manages/destroy_multiple',
        method: "DELETE",
        data: value
    })
  }
}

export {UsersModel}