import axios from 'axios'
import qs from 'qs'
// import {config} from './config.js'
import {notification} from "ant-design-vue"
import router from './../router/index'

const tips = {
  1: '抱歉，出现了一个错误',
  '000001': 'session-id或timestamp参数为空',
  '000002': 'timestamp参数不合法',
  '000003': 'timestamp时间不正确',
  '000004': 'session-id不正确',
  '000005': 'session-id已经过期'
}

class HTTP {
  request({url, data={}, method="GET"}) {
    return new Promise((resolve, reject) => {
      this._request(url, resolve, reject, data, method)
    })
  }

  _request(url, resolve, reject, data={}, method="GET") {
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    let params = ''

    if(url != 'admins/sign_in') {
      headers['Authorization'] = 'Bearer ' +  window.localStorage.getItem('token')
      headers['Timestamp'] = Math.round(new Date().getTime() / 1000)
    } 
    url = url.indexOf('?') == '-1' ? url + '?_=' + (new Date()).getTime() : url + '&_=' + (new Date()).getTime()
    if (method.toLocaleLowerCase() === 'get') {
      params = 'params'
    } else if (method.toLocaleLowerCase() === 'post' || method.toLocaleLowerCase() === 'patch' || method.toLocaleLowerCase() === 'put' || method.toLocaleLowerCase() === 'delete') {
        params = 'data'
        data = qs.stringify(data)
    }
    axios({
      url: '/api/' + url,
      [params]: data,
      method: method, 
      headers: headers,
    }).then(res => {
        let code = res.status.toString()
        if(code.startsWith('2')) {
            if(res.data.code == '000005') {
              notification.error({
                message: '提示',
                description: tips[res.data.code],
                duration: 2
              });
              window.localStorage.removeItem('token')
              router.push({
                path: `/login`
              })
            } else {
              resolve(res.data)
            }
        } else {
            reject()
            let error_code = res.data.error_code
            notification.error({
              message: '提示',
              description: tips[error_code],
              duration: 2
            });
        }
    }).catch((error) => {
      reject(error)
    });
  }
}

export {HTTP}