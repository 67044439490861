import * as types from './mutation-types'

const mutations = {
  [types.LOGIN_USER] (state, user) {
    state.user = user
  },

  [types.LOGIN_ID] (state, id) {
    state.id = id
  },

  [types.PARENT_NAME] (state, parentName) {
    state.parentName = parentName
  }
}
export default mutations