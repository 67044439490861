import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login/Login'
import Layout from '@/layouts/Layout.vue'


Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/login',
      name: '登录页',
      component: Login,
    },
    {
      path: '/',
      redirect: '/home',
      name: '首页',
      component: Layout,
      meta: {
        isLogin: true
      },
      children: [
        {
          path: 'home',
          name: '首页',
          component: () => import('@/pages/home/Home'),
          icon: 'none'
        },
      ]
    },
    {
      path: '/dashboard',
      name: '首页',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/dashboard',
          name: '官网首页',
          component: () => import('@/pages/dashboard/Dashboard'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/dashboard/:id(\\d+)',
          name: '',
          component: () => import('@/pages/dashboard/DashboardDetails'),
          meta: {
            cate: 2
          },
        }
      ]
    },
    {
      path: '/board/softlists',
      name: '软件列表',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/board/softlists',
          name: '软件列表',
          component: () => import('@/pages/board/softlists/SoftLists'),
          meta: {
            cate: 1
          },
        },
        {
          path: '/board/softlists/:id(\\d+)',
          name: '',
          component: () => import('@/pages/board/softlists/SoftDetail'),
          meta: {
            cate: 2
          },
        }
      ]
    },
    {
      path: '/board/categories',
      name: '软件管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/board/categories',
          name: '分类管理',
          component: () => import('@/pages/board/cate/Categories'),
          meta: {
            cate: 1
          },
        },
        {
          path: '/board/categories/:id(\\d+)',
          name: '',
          component: () => import('@/pages/board/cate/CateDetails'),
          meta: {
            cate: 2
          },
        }
      ]
    },
    {
      path: '/board/mainApps',
      name: '分组管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/board/mainApps',
          name: '分组管理',
          component: () => import('@/pages/board/mainApp/MainApps'),
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/board/pay_softs',
      name: '收费标准',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/board/pay_softs',
          name: '收费标准',
          component: () => import('@/pages/board/pay_softs/PaySoftsList'),
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/board/charges',
      name: '收费规则',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/board/charges',
          name: '收费规则',
          component: () => import('@/pages/board/charges/ChargesList'),
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/memberManage',
      name: '会员管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/memberManage',
          name: '会员管理',
          component: () => import('@/pages/member/MemberManage'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/documents',
      component: Layout,
      meta: { isLogin: true },
      children: [
        {
          path: '/documents',
          name: '文档列表',
          component: () => import('@/pages/document/list/index'),
          meta: {
            cate: 1
          }
        },
        {
          path: ':id(\\d+)',
          name: '维护文档',
          component: () => import('@/pages/document/list/details/ShowTab'),
          meta: { cate: 2 }
        },
        {
          path: 'category',
          name: '文档种类',
          component: () => import('@/pages/document/category/index'),
          meta: {
            cate: 1
          }
        },
        {
          path: 'category/:id(\\d+)',
          name: '',
          component: () => import('@/pages/document/category/second_category/index'),
          meta: {
            cate: 2
          }
        },
        {
          path: 'category/:id(\\d+)/:parentId(\\d+)',
          name: '',
          component: () => import('@/pages/document/category/third_category/index'),
          meta: {
            cate: 3
          }
        }
      ]
    },
    {
      path: '/note',
      name: '系统日志',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/note',
          name: '系统日志',
          component: () => import('@/pages/note/Note'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/searchNote',
      name: '系统查询',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/searchNote',
          name: '搜索记录',
          component: () => import('@/pages/searchNote/SearchNote'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/message',
          name: '用户留言',
          component: () => import('@/pages/message/Message'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/integralAcquisitionRecord',
          name: '积分获取记录',
          component: () => import('@/pages/searchNote/PointGainRecord'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/integralConsumptionRecord',
          name: '积分消费记录',
          component: () => import('@/pages/searchNote/PointStatementRecord'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/integralBuyRecord',
          name: '积分购买记录',
          component: () => import('@/pages/searchNote/PointPurchaseRecord'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
        {
          path: '/integralSubscribeRecord',
          name: '会员订阅记录',
          component: () => import('@/pages/searchNote/VipSubscribeOrder'),
          icon: 'none',
          meta: {
            cate: 1
          },
        },
      ]
    },
    {
      path: '/user',
      name: '后台用户管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/user',
          name: '后台用户管理',
          component: () => import('@/pages/user/User'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/ads',
      name: '广告管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/ads',
          name: '广告管理',
          component: () => import('@/pages/ads/Ads'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/vipsets',
      name: 'VIP 套餐管理',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/vipsets',
          name: 'VIP 套餐管理',
          component: () => import('@/pages/vipsets/Vipsets'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/setting',
      name: '系统设置',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: '/setting',
          name: '系统设置',
          component: () => import('@/pages/setting/Setting'),
          icon: 'none',
          meta: {
            cate: 1
          },
        }
      ]
    },
    {
      path: '/',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: 'task',
          component: () => import('@/pages/task/Task.vue'),
          children: [
            {
              path: 'comments',
              name: '评论',
              component: () => import('@/pages/task/comments/index.vue'),
              meta: {
                cate: 1
              }
            },
            {
              path: 'user_upload_records',
              name: '会员上传审核',
              component: () => import('@/pages/task/user_upload_records/UserUploadRecordList.vue'),
              meta: {
                cate: 1
              }
            },
            {
              path: 'reports',
              name: '举报审核',
              component: () => import('@/pages/task/reports/index.vue'),
              meta: {
                cate: 1
              }
            }
          ]
        }
      ]
    },
    {
      path: '/task/user_upload_records',
      component: Layout,
      icon: 'none',
      meta: {
        isLogin: true
      },
      children: [
        {
          path: ':id(\\d+)',
          name: '审核',
          component: () => import('@/pages/task/user_upload_records/ApprovalUserUploadRecord.vue'),
          meta: {
            cate: 2
          }
        },
        {
          path: ':id(\\d+)/show',
          name: '详情',
          component: () => import('@/pages/task/user_upload_records/ShowUserUploadRecord.vue'),
          meta: {
            cate: 2
          }
        },
        {
          path: ':id(\\d+)/preview',
          name: '预览',
          component: () => import('@/pages/task/user_upload_records/Preview.vue'),
          meta: {
            cate: 2
          }
        },
      ]
    },
    {
      path: '*',
      component: () => import('@/pages/errorPage/404'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(res => res.meta.isLogin)) {//判断是否需要登录
    if (localStorage.getItem('token')) {
      next();
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    }

  } else {
    next()
  }

  /*如果本地 存在 token 则 不允许直接跳转到 登录页面*/
  if (to.path == "/login") {
    if (localStorage.getItem('token')) {
      next({
        path: '/home'
      });
    } else {
      next();
    }
  }
});

export default router;
