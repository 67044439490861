<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider
      :trigger="null"
      collapsible
      v-model="collapsed"
      :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }"
    >
      <div class="logo">
        <img src="@/assets/img/logo.png" alt />
      </div>
      <a-menu
        theme="dark"
        mode="inline"
        :defaultSelectedKeys="[routh]"
        :selectedKeys="[routh]"
        :defaultOpenKeys="['/' + $route.path.split('/')[1]]"
      >
        <template v-for="item in menuList">
          <a-menu-item
            v-if="!item.children"
            :key="item.key"
            @click="menu(item.key)"
          >
            <a-icon :type="item.icon" />
            <span>{{ item.title }}</span>
          </a-menu-item>
          <a-sub-menu v-else :key="item.key">
            <span slot="title">
              <a-icon :type="item.icon" />
              <span>{{ item.title }}</span>
            </span>
            <template v-for="item in item.children">
              <a-menu-item :key="item.key" @click="menu(item.key)">{{
                item.title
              }}</a-menu-item>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
    <a-layout :style="{ marginLeft: collapsed == true ? '80px' : '200px' }">
      <Header @collapseAction="collapseAction" :collapsed="collapsed" />
      <a-layout-content :class="[flag ? 'layContent1' : 'layContent2']">
        <BreadCrumb v-show="this.$route.path != '/home'" />
        <router-view style="padding: 12px"></router-view>
      </a-layout-content>
      <Footer v-if="this.$route.path != '/home'" />
    </a-layout>
  </a-layout>
</template> 
<script>
import Footer from "./BasicFooter";
import Header from "./BasicHeader";
import BreadCrumb from "./BasicBread";
import menuList from "./menu";
export default {
  data() {
    return {
      collapsed: false,
      menuList: menuList,
      routh: "",
    };
  },

  computed: {
    flag() {
      return this.$route.path != "/home" ? true : false;
    },
  },

  created() {
    this.getRouth();
  },

  methods: {
    collapseAction() {
      this.collapsed = !this.collapsed;
    },

    menu(s) {
      this.$router.push(s);
    },

    getRouth() {
      if (Object.keys(this.$route.params).length > 0) {
        this.routh = this.$route.path.substr(
          0,
          this.$route.path.indexOf(this.$route.params.id.toString()) - 1
        )
      } else {
        this.routh = this.$route.path;
      }
    },
  },

  watch: {
    $route(to) {
      if (to.path == "/home") {
        this.routh = "";
      } else {
        this.getRouth();
      }
    },
  },

  components: {
    Footer,
    Header,
    BreadCrumb,
  },
};
</script>
<style>
#components-layout-demo-fixed-sider .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-fixed-sider .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-fixed-sider .logo {
  text-align: center;
}

.upload-word {
  margin-top: -5px !important;
}

.layContent1 {
  margin: 12px;
  background: #fff;
  min-height: calc(100vh - 157px) !important;
}

.layContent2 {
  margin-top: 12px;
  margin-left: 12px;
  margin-right: 12px;
  background: #fff;
  min-height: calc(100vh - 76px) !important;
}

.ant-layout {
  background: #fff !important;
}
</style>
