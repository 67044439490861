<template>
  <div class="container">
    <div class="content">
      <div class="top">
        <div class="header">
          <img alt="logo" class="logo" src="../../assets/img/logo.png" />
          <span class="title">软猫下载</span>
        </div>
        <div class="desc">softmail 是华东地区最具影响力的下载平台</div>
      </div>
      <div class="login">
        <a-form @submit="onSubmit" :form="form">
          <a-form-item>
            <a-input
              type="text"
              size="large"
              placeholder="请输入用户名"
              autocomplete="off"
              v-decorator="['admin_name', {rules: [{ required: true, message: '请输入用户名', whitespace: true}]}]"
            >
              <a-icon slot="prefix" type="user" />
            </a-input>
          </a-form-item>    
          <a-form-item>
            <a-input
              size="large"
              placeholder="请输入密码"
              autocomplete="off"
              type="password"
              v-decorator="['password', {rules: [{ required: true, message: '请输入密码', whitespace: true}]}]"
            >
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-item>
          <div>
            <a-checkbox :checked="true" aria-disabled="true">记住我</a-checkbox>
            <a style="float: right">忘记密码</a>
          </div>
          <a-form-item>
            <a-button
              :loading="logging"
              style="width: 100%;margin-top: 24px"
              size="large"
              htmlType="submit"
              type="primary"
            >登录</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginModel } from "../../models/login";
import { mapMutations } from "vuex";
const loginModel = new LoginModel();
export default {
  name: "Login",
  data() {
    return {
      logging: false,
      error: "",
      form: this.$form.createForm(this)
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.logging = true;
          loginModel.login(values).then(res => {
            this.logging = false;
            if (res.code == "0") {
              console.log(this.checkNick)
              window.localStorage.setItem("token", res.result.token);
              this.loginUser(res.result.admin_name);
              this.loginId(res.result.id);
              this.$router.push("/");
            } else {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              });
            }
          });
        }
      });
    },

    ...mapMutations({
      loginUser: "LOGIN_USER",
      loginId: "LOGIN_ID",
    })
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5
    url("https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg")
    no-repeat center 110px;
  background-size: 100%;
  .content {
    padding: 32px 0;
    flex: 1;
    @media (min-width: 768px) {
      padding: 112px 0 24px;
    }
    .top {
      text-align: center;
      .header {
        height: 44px;
        line-height: 44px;
        a {
          text-decoration: none;
        }
        .logo {
          height: 44px;
          vertical-align: top;
          margin-right: 16px;
        }
        .title {
          font-size: 33px;
          color: rgba(0, 0, 0, 0.85);
          font-family: "Myriad Pro", "Helvetica Neue", Arial, Helvetica,
            sans-serif;
          font-weight: 600;
          position: relative;
          top: 2px;
        }
      }
      .desc {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 12px;
        margin-bottom: 40px;
      }
    }
    .login {
      width: 368px;
      margin: 0 auto;
      @media screen and (max-width: 576px) {
        width: 95%;
      }
      @media screen and (max-width: 320px) {
        .captcha-button {
          font-size: 14px;
        }
      }
      .icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }
    }
  }
}
</style>
