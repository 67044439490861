import Vue from 'vue'
import router from './router/index'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css';
import './assets/css/common.scss'
import store from './store'
import VeLine from 'v-charts/lib/line.common'
import VePie from 'v-charts/lib/pie.common'
import VeHistogram from 'v-charts/lib/histogram.common'
import App from './App.vue'
import './utils/filter'

Vue.component(VeLine.name, VeLine)
Vue.component(VePie.name,VePie)
Vue.component(VeHistogram.name,VeHistogram)

// Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Antd)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
