import {HTTP} from '../utils/axios'

class LoginModel extends HTTP {
  /*登录 */
  login(value) {
      return this.request({
          url: 'admins/sign_in',
          method: "POST",
          data: value
      })
  }
}

export {LoginModel}