<template>
  <a-layout-footer style="text-align: center">
    © 2019 CopyRight <a :href="contact" >联系我们</a><a :href="terms">使用协议</a>
  </a-layout-footer>
</template>

<script>
  export default {
    data() {
      return {
        contact: process.env.VUE_APP_PORT + '/contact',
        terms: process.env.VUE_APP_PORT + '/terms'
      }
    }
  }
</script>

<style lang="scss">
  .ant-layout-footer a {
    margin-left: 20px;
  }
</style>