import Vue from 'vue'
import Vuex from 'vuex'
import state from './state.js'
import * as getters from './getters'
import mutations from './mutations'
import createLogger from 'vuex/dist/logger'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state,
  getters,
  mutations,
  strict: debug,
  plugins: [createLogger(), persistedState({
    storage: window.localStorage,
    reducer(val) {
      return {
        user: val.user,
        id: val.id,
        parentName: val.parentName
      }
    }
  })]
})