const menuList = [
  {
    title: '官网首页',
    key: '/dashboard',
    icon: 'home'
  },
  {
      title: '软件管理',
      key: '/board',
      icon: 'codepen',
      children: [
          {
              title: '软件列表',
              key: '/board/softlists',
          },
          {
              title: '分类管理',
              key: '/board/categories',
          },
          {
              title: '分组管理',
              key: '/board/mainApps',
          },
          {
            title: '收费规则',
            key: '/board/charges',
          },{
            title: '收费标准',
            key: '/board/pay_softs',
          }
      ]
  },
  {
    title: '文档管理',
    key: '/documents',
    icon: 'file-text',
    children: [
      {
        title: '文档列表',
        key: '/documents'
      },
      {
        title: '文档种类',
        key: '/documents/category'
      }
    ]
  }, 
  {
    title: '会员管理',
    key: '/memberManage',
    icon: 'form'
  },
  {
    title: '业务审核',
    key: '/task/comments',
    icon: 'tag'
  },
  {
    title: '广告管理',
    key: '/ads',
    icon: 'audit'
  },
  {
    title: 'VIP 套餐管理',
    key: '/vipsets',
    icon: 'bars'
  },
  {
    title: '系统设置',
    key: '/setting',
    icon: 'setting'
  },
  {
    title: '后台用户管理',
    key: '/user',
    icon: 'user'
  },
  {
    title: '系统日志',
    key: '/note',
    icon: 'database'
  },
  {
    title: '系统查询',
    key: '/searchNote',
    icon: 'search',
    children: [
      {
        title: '积分获取记录',
        key: '/integralAcquisitionRecord',
      },
      {
        title: '积分消费记录',
        key: '/integralConsumptionRecord',
      },
      {
        title: '积分购买记录',
        key: '/integralBuyRecord',
      },
      {
        title: '会员订购记录',
        key: '/integralSubscribeRecord',
      },
      {
        title: '搜索记录',
        key: '/searchNote',
      },
      {
        title: '留言列表',
        key: '/message',
      }
    ]
  }
];
export default menuList;
