<template>
  <div>
    <a-breadcrumb style="background: #f2f2f2; padding: 12px">
      <a-breadcrumb-item v-for="(item, index) in breadList" :key="index">
        <router-link :to="{ path: item.path }">{{ item.name }}</router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      breadList: [], // 路由集合
    };
  },

  computed: {
    ...mapGetters(["parentName"]),
  },

  watch: {
    $route(to) {
      var index =  this.breadList.findIndex(item => item.path === to.fullPath)
      if (index !== -1) {
        //如果存在路由列表，则把之后的路由都删掉
        this.breadList.splice(index + 1, this.breadList.length - index - 1);
      } else {
        if (to.meta.cate == 1) {
          this.breadList = [];
          this.breadList.push({
            name: to.name,
            path: to.fullPath
          })
        } else {
          this.breadList.push({
            name: to.name? to.name : this.parentName,
            path: to.fullPath,
          });
        }
      }
      window.localStorage.setItem("bread", JSON.stringify(this.breadList));
    },
  },

  created() {
    if (window.localStorage.getItem("bread")) {
      this.breadList = JSON.parse(window.localStorage.getItem("bread"));
    } else {
      this.breadList.push({ name: "官网首页", path: "/home" });
    }
  },
};
</script>

<style lang="scss">
.ant-breadcrumb .ant-breadcrumb-link {
  font-size: 18px;
}

.ant-breadcrumb .router-link-active {
  color: #1890ff;
}

.ant-breadcrumb a.router-link-exact-active {
  color: rgba(0, 0, 0, 0.45);
}
</style>
