import Vue from 'vue'

// Numeral.js http://numeraljs.com/
const numeral = require('numeral')

export function formatCurrency(value) {
  return numeral(value).format('0,0.00')
}

export function formatBigNumber(value) {
  return numeral(value).format('0,0')
}

export function bToMbGb(bValue) {
  let result = ''
  if (!bValue) {
    return ''
  }
  if (bValue < 0) {
    bValue = 0
  }
  if (bValue < -1048576 && bValue / 1048576.0 > -1048576) {
    result = (bValue / 1048576.0).toFixed(2) + ' MB'
  } else if (bValue < 1048576 && bValue >= -1048576) {
    result = (bValue / 1024).toFixed(2) + ' KB'
  } else if (bValue >= 1048576 && bValue / 1048576.0 < 1048576) {
    result = (bValue / 1048576.0).toFixed(2) + ' MB'
  } else {
    result = (bValue / 1073741824.0).toFixed(2) + ' GB'
  }
  return result
}

// 保留两位小数，每三位用逗号分隔
Vue.filter('formatCurrency', function(value) {
  if (value === '' || value === '待设置') {
    return value
  }

  return formatCurrency(value)
})

// 每三位用逗号分隔
Vue.filter('formatBigNumber', function(value) {
  if (value === '') {
    return value
  }

  return formatBigNumber(value)
})

// 保留两位小数，每三位用逗号分隔
Vue.filter('bToMbGb', function(value) {
  return bToMbGb(value)
})
