<template>
<a-layout-header style="background: #fff; padding: 0">
  <a-row>
    <a-col :span="16">
      <a-icon class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="collapseAction" />
    </a-col>
    <a-col :span="8" style="text-align: right;padding-right: 15px;">
      <router-link  class="home" active-class="u-home" to="/home"><a-icon type="home" />首页</router-link>
      <a-dropdown>
        <a class="ant-dropdown-link" @click="e => e.preventDefault()"> {{user}}
          <a-icon type="down" />
          <a-icon type="team" /></a>
        <a-menu slot="overlay">
          <a-menu-item key="0">
            <a rel="noopener noreferrer" href="javascript:void(0)">
              <a-icon type="user" />
              <span>我的账号</span>
            </a>
          </a-menu-item>
          <a-menu-item key="1">
            <a rel="noopener noreferrer" href="javascript:void(0)">
              <a-icon type="edit" />
              <span @click="handleEditFile('file')">修改资料</span>
            </a>
          </a-menu-item>
          <a-menu-item key="2">
            <a rel="noopener noreferrer" href="javascript:void(0)">
              <a-icon type="lock" />
              <span @click="handleEditFile('pssword')">修改密码</span>
            </a>
          </a-menu-item>
          <a-menu-item key="3">
            <a rel="noopener noreferrer" href="javascript:void(0)" @click="handleLogout">
              <a-icon type="diff" />
              <span>退出</span>
            </a>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-col>
  </a-row>
  <a-modal
      :title="title"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form :form="form2" :label-col="{ span: 8}" :wrapper-col="{ span: 16 }">
        <div v-if="title=='修改资料'">
          <a-form-item label="用户账号">
            <a-input :value="user" :disabled="true" />
          </a-form-item>
          <a-form-item label="联系人">
            <a-input v-decorator="['contact', {rules: [{ required: true, message: '请输入联系人!' }] }]" />
          </a-form-item>
          <a-form-item label="联系电话">
            <a-input v-decorator="['phone', {rules: [{ required: true, message: '请输入联系电话!' }] }]" />
          </a-form-item>
          <a-form-item label="邮箱">
            <a-input type="email" v-decorator="['email', {rules: [{ required: true, message: '请输入邮箱!' }] }]" />
          </a-form-item>
        </div>
        <div v-else>
          <a-form-item label="原密码">
            <a-input v-decorator="['old_password', {rules: [{ required: true, message: '请输入原密码!' }] }]" />
          </a-form-item>
          <a-form-item label="新密码">
            <a-input v-decorator="['password', {rules: [{ required: true, message: '请输入新密码!' }] }]" />
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input v-decorator="['confirm_password', {rules: [{ required: true, message: '请再次输入新密码!' }] }]" />
          </a-form-item>
        </div>
      </a-form>
    </a-modal>
</a-layout-header>
</template>

<script>
import { UsersModel } from "../models/user";
const usersModel = new UsersModel();
import {mapGetters} from 'vuex'
export default {
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      visible: false,
      form2: this.$form.createForm(this, {
        name: 'coordinated'
      }),
      title: ''
    }
  },

  created() {
  },

  computed: {
    ...mapGetters([
      'user',
      'id'
    ])
  },

  methods: {
    collapseAction() {
      this.$emit('collapseAction');
    },

    handleLogout() {
      window.localStorage.clear()
      this.$router.push({
        path: '/login',
        query:{
          redirect: this.$route.path
        }
      })
    },

    handleEditFile(title) {
      console.log(title)
      if(title == 'file') {
        this.title = "修改资料"
      } else {
        this.title = "修改密码"
      }
      console.log(this.title)
      this.visible = true
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          let url = "board/admin_manages/" + this.id;
          if(this.title == "修改密码") {
            if(values.password.trim() != values.confirm_password.trim()) {
              return
            } else {
              delete values.confirm_password
            }
          }
          usersModel.update(values, url).then(res => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              });
              this.visible = false;
              window.localStorage.clear()
              this.$router.push({
                path: '/login',
                query:{
                  redirect: this.$route.path
                }
              })
            }
          });
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-dropdown-menu-item span {
  margin-left: 15px;
}

.anticon-team {
  margin-left: 5px;
  font-size: 18px;
}

.home {
  width: 80px;
  display: inline-block;
  text-align: center;
  margin-right: 30px;
  background: #f2f2f2;
  cursor: pointer;
}

a.u-home {
  background: #20a0ff;
  color: #fff !important;
}
</style>
